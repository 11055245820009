var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news mb-5"},[_c('br'),_c('h2',{staticClass:"primary--text",attrs:{"align":"center"}},[_vm._v("Lao-US Alumni News")]),_c('br'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Accounts requiring verification")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.renderAccountHeaders,"items":_vm.pendingAccounts,"items-per-page":5,"disable-filtering":"","disable-sort":"","footer-props":{
            prevIcon: 'mdi-arrow-left-thin-circle-outline',
            nextIcon: 'mdi-arrow-right-thin-circle-outline',
            itemsPerPageText: 'Display:',
            itemsPerPageOptions: [ 5, 10, 20, 50, -1 ],
          }},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"secondary--text",attrs:{"href":'/admin/users/' + item.id}},[_vm._v(" "+_vm._s(_vm._f("htmlEntities")(item.fullName))+" ")])]}},{key:"item.certificate",fn:function(ref){
          var item = ref.item;
return [(item.programs[0].certificate)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":_vm.apiBase + '/uploads' + item.programs[0].certificate,"title":"Download Certificate","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.facebook",fn:function(ref){
          var item = ref.item;
return [(item.facebook)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":'https://www.facebook.com/' + item.facebook,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-facebook")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.linkedin",fn:function(ref){
          var item = ref.item;
return [(item.linkedin)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":'https://www.linkedin.com/in/' + item.linkedin,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.emailVerified",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":""},model:{value:(item.emailVerified),callback:function ($$v) {_vm.$set(item, "emailVerified", $$v)},expression:"item.emailVerified"}})]}},{key:"item.approve",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 success--text",attrs:{"title":'Approve ' + item.fullName},on:{"click":function($event){return _vm.approveUser( item )}}},[_vm._v(" mdi-account-check ")]),_c('v-icon',{staticClass:"error--text",attrs:{"title":'Deny ' + item.fullName},on:{"click":function($event){return _vm.denyUser( item )}}},[_vm._v(" mdi-account-cancel ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Programs requiring verification")]),_c('i',{staticClass:"grey--text",attrs:{"small":""}},[_vm._v("Excluding new accounts")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.renderProgramHeaders,"items":_vm.pendingPrograms,"items-per-page":5,"disable-filtering":"","disable-sort":"","footer-props":{
            prevIcon: 'mdi-arrow-left-thin-circle-outline',
            nextIcon: 'mdi-arrow-right-thin-circle-outline',
            itemsPerPageText: 'Display:',
            itemsPerPageOptions: [ 5, 10, 20, 50, -1 ],
          }},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"secondary--text",attrs:{"href":'/admin/users/' + item.user.id}},[_vm._v(" "+_vm._s(_vm._f("htmlEntities")(item.user.fullName))+" ")])]}},{key:"item.country",fn:function(ref){
          var item = ref.item;
return [_c('country-flag',{staticStyle:{"vertical-align":"text-bottom"},attrs:{"country":item.country.iso,"size":"normal","aria-label":item.country.name,"alt":item.country.name,"title":item.country.name}})]}},{key:"item.certificate",fn:function(ref){
          var item = ref.item;
return [(item.certificate)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":_vm.apiBase + '/uploads' + item.certificate,"title":"Download Certificate","target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.approve",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 success--text",attrs:{"title":'Approve ' + item.fullName},on:{"click":function($event){return _vm.approveProgram( item )}}},[_vm._v(" mdi-account-check ")]),_c('v-icon',{staticClass:"error--text",attrs:{"title":'Deny ' + item.fullName},on:{"click":function($event){return _vm.denyProgram( item )}}},[_vm._v(" mdi-account-cancel ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }