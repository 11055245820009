var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news mb-5"},[_c('br'),_c('h2',{staticClass:"primary--text",attrs:{"align":"center"}},[_vm._v("Lao-US Alumni News")]),_c('br'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Edit news article")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"overflow":"hidden"},attrs:{"width":"100%"}},[_c('v-card-text',{staticClass:"pt-4"},[_c('validation-observer',{ref:"observer"},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"Article title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","dense":"","label":"Category","items":_vm.categories,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-checkbox',{attrs:{"color":"secondary","label":"Article Published?"},model:{value:(_vm.form.published),callback:function ($$v) {_vm.$set(_vm.form, "published", $$v)},expression:"form.published"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-checkbox',{attrs:{"color":"secondary","label":"Article Visible?"},model:{value:(_vm.form.visible),callback:function ($$v) {_vm.$set(_vm.form, "visible", $$v)},expression:"form.visible"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"4"}},[_vm._v(" Publish Date: "),_c('date-picker',{attrs:{"placeholder":"Select Date Time","type":"datetime"},on:{"change":_vm.updtePublishDate},model:{value:(_vm.form.publishDate),callback:function ($$v) {_vm.$set(_vm.form, "publishDate", $$v)},expression:"form.publishDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Article title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Article title","error-messages":errors},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.hasNoThumbnail)?_c('validation-provider',{attrs:{"name":"Article Thumbnail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-file-input',{attrs:{"accept":".jpg, .jpeg, .png","rules":[ function (file) { return _vm.fileValidationCheck( file ) || validate( file ); } ],"label":"Article Thumbnail","hint":"File formats: PNG, JPG (max 500KB)","persistent-hint":"","outlined":"","dense":"","show-size":"","error-messages":errors,"success":valid},on:{"change":_vm.updateThumbnailPreview},model:{value:(_vm.form.thumbnail),callback:function ($$v) {_vm.$set(_vm.form, "thumbnail", $$v)},expression:"form.thumbnail"}})]}}],null,false,2453851415)}):_c('div',[_vm._v(" Article Thumbnail:"),_c('br'),_c('br'),_c('span',{staticClass:"filelink"},[_vm._v(" "+_vm._s(_vm.form.thumbnail)+"   "),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.updateThumbnailPreview()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)],1)])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{staticStyle:{"max-height":"250px","max-width":"250px"},attrs:{"src":_vm.thumbnail,"contain":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Summary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"Article Summary","error-messages":errors},model:{value:(_vm.form.summary),callback:function ($$v) {_vm.$set(_vm.form, "summary", $$v)},expression:"form.summary"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Article Content: "),_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Editor',{attrs:{"editorToolbar":_vm.toolbar,"error-messages":errors},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})]}}])})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-btn',{attrs:{"outlined":"","color":"primary","to":"/admin/news"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-times")]),_vm._v(" Cancel")],1),_c('v-btn',{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.onDelete()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-trash")]),_vm._v(" Delete")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-save")]),_vm._v("Save")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }