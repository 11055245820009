var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news mb-5"},[_c('br'),_c('h2',{staticClass:"primary--text",attrs:{"align":"center"}},[_vm._v("Lao-US Alumni News")]),_c('br'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Lao-US Alumni Accounts")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.renderAccountHeaders,"items":_vm.pendingAccounts,"items-per-page":10,"disable-filtering":"","disable-sort":"","footer-props":{
            prevIcon: 'mdi-arrow-left-thin-circle-outline',
            nextIcon: 'mdi-arrow-right-thin-circle-outline',
            itemsPerPageText: 'Display:',
            itemsPerPageOptions: [ 5, 10, 20, 50, -1 ],
          }},scopedSlots:_vm._u([{key:"top",fn:function(ref){
          var pagination = ref.pagination;
          var options = ref.options;
          var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[ 5, 10, 20, 50, -1 ],"items-per-page-text":"Display:","prev-icon":"mdi-arrow-left-thin-circle-outline","next-icon":"mdi-arrow-right-thin-circle-outline"},on:{"update:options":updateOptions}})]}},{key:"item.fullName",fn:function(ref){
          var item = ref.item;
return [_c('a',{staticClass:"secondary--text",attrs:{"href":'/admin/users/' + item.id}},[_vm._v(" "+_vm._s(_vm._f("htmlEntities")(item.fullName))+" ")])]}},{key:"item.programs",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.programs.length)+" ")]}},{key:"item.facebook",fn:function(ref){
          var item = ref.item;
return [(item.facebook)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":'https://www.facebook.com/' + item.facebook,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-facebook")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.linkedin",fn:function(ref){
          var item = ref.item;
return [(item.linkedin)?_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","icon":"","color":"secondary","href":'https://www.linkedin.com/in/' + item.linkedin,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-linkedin")])],1):_c('span',[_vm._v(" N/A ")])]}},{key:"item.emailVerified",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":""},model:{value:(item.emailVerified),callback:function ($$v) {_vm.$set(item, "emailVerified", $$v)},expression:"item.emailVerified"}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }