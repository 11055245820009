var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"5"}},[_c('v-card',{staticClass:"rounded-lg pb-6 mx-auto",attrs:{"max-width":"550px"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-center"},[_c('v-container',{staticClass:"my-8"},[_c('v-spacer'),_c('span',{staticClass:"text-h6 text-center",staticStyle:{"word-break":"break-word"}},[_vm._v(" Register to connect with fellow US Alumni of Laos ")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"id":"firstName","label":"First name","prepend-icon":"fa-user","outlined":"","error-messages":errors,"success":valid},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"id":"lastName","label":"Last name","prepend-icon":"fa-user","outlined":"","error-messages":errors,"success":valid},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"id":"email","type":"email","label":"Email Address","prepend-icon":"fa-at","outlined":"","error-messages":errors,"success":valid},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"id":"password","label":"Password","hint":"Minimum 8 characters","persistent-hint":"","prepend-icon":"fa-lock","type":"password","outlined":"","error-messages":errors,"success":valid},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password","data-vv-as":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"rounded-lg",attrs:{"id":"confirm","label":"Confirm Password","hint":"Confirm your password","persistent-hint":"","prepend-icon":"fa-lock","type":"password","outlined":"","error-messages":errors,"success":valid},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})]}}])})],1)],1),_c('v-row',{staticClass:"mx-1",attrs:{"align":"center"}},[_c('v-divider'),_c('h3',[_vm._v(" What program did you attended ")]),_c('v-divider')],1),_c('v-row',[_c('v-col',[_c('program-add-form',{attrs:{"countries":_vm.countries,"programs":_vm.programs},on:{"updateProgram":_vm.updateForm}})],1)],1),_c('v-row',{staticClass:"mx-0 mb-4",attrs:{"justify":"center","align":"center","align-content":"center"}},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"x-large":"","color":"primary","width":"300px","type":"submit"}},[_vm._v(" Register ")])],1),_c('v-row',{staticClass:"mx-0 mt-6",attrs:{"justify":"center","align":"center","align-content":"center"}},[_c('v-btn',{staticClass:"info--text",attrs:{"to":"/forgot","text":""}},[_vm._v("Forgot password?")]),_vm._v("   "),_c('v-btn',{staticClass:"primary--text",attrs:{"to":"/login","text":""}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }