var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news mb-5"},[_c('br'),_c('h2',{staticClass:"primary--text",attrs:{"align":"center"}},[_vm._v("Lao-US Alumni Events")]),_c('br'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"4"}},[_c('h2',[_vm._v("Events")])]),_c('v-spacer'),_c('v-col',{staticClass:"pb-1 text-right"},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"large":"","color":"primary","to":"/admin/events/add"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-pen")]),_vm._v("Add Event")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.renderHeaders,"items":_vm.events,"item-class":_vm.renderClass,"items-per-page":10,"disable-filtering":"","disable-sort":"","footer-props":{
            prevIcon: 'mdi-arrow-left-thin-circle-outline',
            nextIcon: 'mdi-arrow-right-thin-circle-outline',
            itemsPerPageText: 'Display:',
            itemsPerPageOptions: [ 5, 10, 20, 50, -1 ],
          }},scopedSlots:_vm._u([{key:"top",fn:function(ref){
          var pagination = ref.pagination;
          var options = ref.options;
          var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"items-per-page-options":[ 5, 10, 20, 50, -1 ],"items-per-page-text":"Display:","prev-icon":"mdi-arrow-left-thin-circle-outline","next-icon":"mdi-arrow-right-thin-circle-outline"},on:{"update:options":updateOptions}})]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('a',{staticClass:"secondary--text",attrs:{"title":_vm._f("htmlEntities")(item.name),"href":'/admin/events/' + item.id}},[_vm._v(" "+_vm._s(_vm._f("htmlEntities")(item.name))+" ")])])]}},{key:"item.location",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("htmlEntities")(item.location))+" ")]}},{key:"item.startTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatEventDate")(item.startTime))+" ")]}},{key:"item.endTime",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatEventDate")(item.endTime))+" ")]}},{key:"item.visible",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"secondary","disabled":""},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }